<template>
  <div class="home">
    <TabHeader ref="Header" :active="0" @merchants="goMerchants"></TabHeader>

    <div class="home_center">
      <div class="indexBg">
        <!-- <img class="indexBg_img" src="../../assets/img/indexBg.png" /> -->
      </div>

      <div class="content">
        <!-- 图表 -->
        <div class="chart">
          <div
            class="chart_item"
            v-for="(item, index) in changeList"
            :key="index"
            @click.stop="goSmoke"
          >
            <div style="color: #808080">{{ item[0].Factory }}</div>

            <div v-for="(items, indexJ) in item.slice(0, 3)" :key="items.Id">
              <div class="name_price" :class="indexJ != 0 ? 'margin13' : ''">
                <span class="name">{{ items.Title }}</span>
                <span class="price" :class="items.classColor">
                  {{ items.value }}
                  <span v-if="items.classColor == 'red'">↑</span>
                  <span v-else>↓</span>
                  {{ items.changeValue1 }}
                </span>
              </div>
              <div style="color: #b2b2b2">代码：{{ items.FactoryCode }}</div>
              <div class="myChart" v-if="indexJ == 0">
                <div
                  :id="'myChart' + index"
                  style="width: 190px; height: 86px"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- 推荐 -->
        <Recommended
          :smokeAllList="smokeAllList"
          :wineAllList="wineAllList"
          :factoryList="factoryList"
          :releaseList="releaseList"
          :province="province"
          :city="city"
          @changeRelease="changeRelease"
          @checkRoute="checkRoute"
          style="margin-top: 25px"
        ></Recommended>
      </div>
    </div>

    <AllFooter ref="AllFooter"></AllFooter>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'home',
  data() {
    return {
      // 烟酒异动
      smokeAllList: [],
      wineAllList: [],
      // 烟厂列表
      factoryList: [],
      // 商户发布列表
      releaseList: [],

      changeList: [],

      releaseName: '推荐',
      province: '广东省',
      city: '汕头市',

      myChartList: {
        flag: false,
        list: [],
      },
      myCharts: {},
    }
  },

  mounted() {},

  created() {
    this.getUpdateList()
    this.getFactory()
    this.getArticleList({
      pageindex: 1,
      isRecommend: true,
      isLogin: false,
    })

    // eslint-disable-next-line no-undef
    var geolocation = new qq.maps.Geolocation(
      'Q3JBZ-7ETKU-QVEV4-23XI2-P4GB3-22BN7',
      'web-tea'
    )

    geolocation.getLocation(
      (res) => {
        console.log(res)
        this.province = res.province
        this.city = res.city
      },
      (err) => {
        console.log(err)
      }
    )
  },

  methods: {
    goMerchants() {
      setTimeout(() => {
        this.$nextTick(() => {
          document.documentElement.scrollTo({
            top: 1274,
            behavior: 'smooth',
          })
        })
      }, 200)
    },

    // 走势图
    setEcharts(id, xData, yData, color1, color2) {
      if (!document.getElementById(id)) return
      if (this.myChartList.list.indexOf(id) > -1) return
      this.myChartList.list.push(id)
      if (this.myCharts[id]) {
        this.myCharts[id].dispose()
      }
      this.myCharts[id] = echarts.init(document.getElementById(id))

      let option = {
        title: {
          show: false,
          text: 'ECharts图表',
        },
        legend: {
          show: false,
        },
        grid: {
          left: '0px',
          top: '0px',
          right: '0px',
          bottom: '0px',
        },
        xAxis: {
          show: false,
          type: 'category',
          boundaryGap: false,
          data: xData,
        },
        yAxis: {
          show: false,
          type: 'value',
          max: this.$globalFun.max(yData) + 2,
          min: this.$globalFun.min(yData) - 8,
        },
        color: [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: color1, // 0% 处的颜色
              },
              {
                offset: 1,
                color: color2, // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        ],
        tooltip: {},
        series: [
          {
            data: yData,
            type: 'line',
            symbol: 'circle',
            symbolSize: 8, 
            areaStyle: {
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              shadowBlur: 10,
              shadowOffsetY: 10,
            },
          },
        ],
      }

      option && this.myCharts[id].setOption(option)
    },

    // 异动数据
    async getUpdateList() {
      let date = this.$globalFun.parseTime(new Date(), '{y}/{m}/{d}')

      const form = {
        pageindex: 1,
        category1: '全部',
        date,
        type: 0,
        isChange: 1,
        isLogin: false,
      }

      const { data: offer } = await this.$api.offer.getList(form)

      // form.date = this.$globalFun.parseTime(
      //   new Date(offer.date),
      //   "{y}/{m}/{d}"
      // );
      let list = []
      for (const i of offer.list) {
        list.push(...i.foods)
      }

      // 加跌停颜色类名
      for (const arr of list) {
        let classColor = ''
        let value = 0
        let changeValue = 0

        if (
          arr.OfferValue &&
          arr.RetrieveValue &&
          arr.OfferValue !== arr.RetrieveValue
        ) {
          classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
          value = arr.OfferValue
          changeValue = this.$globalFun.numFormat(
            arr.OfferValue - arr.RetrieveValue
          )
        } else if (
          arr.ZSJValue &&
          arr.ZSJRetrieveValue &&
          arr.ZSJValue !== arr.ZSJRetrieveValue
        ) {
          classColor = this.riseAndFall(arr.ZSJValue, arr.ZSJRetrieveValue)
          value = arr.ZSJValue
          changeValue = this.$globalFun.numFormat(
            arr.ZSJValue - arr.ZSJRetrieveValue
          )
        } else if (
          arr.SZValue &&
          arr.SZRetrieveValue &&
          arr.SZValue !== arr.SZRetrieveValue
        ) {
          classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
          value = arr.SZValue
          changeValue = this.$globalFun.numFormat(
            arr.SZValue - arr.SZRetrieveValue
          )
        } else {
          classColor = this.riseAndFall(arr.SZValue2, arr.SZRetrieveValue2)
          value = arr.SZValue2
          changeValue = this.$globalFun.numFormat(
            arr.SZValue2 - arr.SZRetrieveValue2
          )
        }

        arr.classColor = classColor
        arr.value = value
        arr.changeValue1 = changeValue - 0
      }
      // console.log('异动1', list)

      const form2 = {
        pageindex: 1,
        category1: '国酒',
        date,
        isChange: 1,
        isLogin: false,
      }

      const { data: data1 } = await this.$api.offer.getList(form2)
      form2.category1 = '洋酒'
      const { data: data2 } = await this.$api.offer.getList(form2)

      let list2 = []
      if (data1) {
        for (const i of data1.list) {
          list2.push(...i.foods)
        }

        for (const j of data2.list) {
          list2.push(...j.foods)
        }
      }
      // 加跌停颜色类名
      for (const arr of list2) {
        const classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
        const value = arr.OfferValue
        const changeValue = this.$globalFun.numFormat(
          arr.OfferValue - arr.RetrieveValue
        )

        arr.classColor = classColor
        arr.value = value
        arr.changeValue1 = changeValue - 0
      }

      // console.log(list2)

      this.smokeAllList = list
      this.wineAllList = list2

      this.changeList = this.setChangeList(list)

      this.$nextTick(async () => {
        this.myChartList.list = []
        /*ECharts图表 "#65BD60", "#132312" "#D83E26", "#311615"*/
        this.changeList.forEach(async (item, i) => {
          const id = 'myChart' + i
          let color1 = item[0].classColor == 'red' ? '#D83E26' : '#6CC966'
          let color2 = item[0].classColor == 'red' ? '#000000' : '#000000'

          const { data } = await this.$api.offer.getChart({
            title: item[0].Title,
          })

          let dateList = data.date.slice(0, 7)

          let dataList = []
          if (data.offer[0] > 0) {
            dataList = data.offer.slice(0, 7)
          } else if (data.zsj[0] > 0) {
            dataList = data.zsj.slice(0, 7)
          } else if (data.sz[0] > 0) {
            dataList = data.sz.slice(0, 7)
          } else {
            dataList = data.sz2.slice(0, 7)
          }

          this.setEcharts(
            id,
            dateList.reverse(),
            dataList.reverse(),
            color1,
            color2
          )
        })
      })
    },

    checkRoute() {
      this.$refs.Header.getConfig()
    },

    // 跳转报价列表
    goSmoke() {
      if (
        this.$store.state.tokens &&
        this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1
      ) {
        this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
          confirmButtonText: '续费',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$refs.Header.getConfig()
          })
          .catch(() => {
            // next()
            console.log('取消')
          })
      } else {
        this.$router.push({
          path: '/smoke',
        })
      }
    },

    // 文章类切换
    changeRelease(name) {
      if (this.releaseName == name) return

      this.releaseName = name

      let form = {}

      if (this.releaseName == '关注') {
        form = {
          pageindex: 1,
          type: 0,
        }
      } else if (this.releaseName == '推荐') {
        form = {
          pageindex: 1,
          isRecommend: true,
          isLogin: false,
        }
      } else if (this.releaseName == this.province) {
        form = {
          pageindex: 1,
          isLogin: false,
          IsProvince: 2,
          Province: this.province,
        }
      } else if (this.releaseName == this.city) {
        form = {
          City: this.city,
          isLogin: false,
          pageindex: 1,
        }
      }

      this.getArticleList(form)
    },

    // 判断涨跌
    riseAndFall(newV, oldV) {
      const num = newV - oldV

      if (num > 0) {
        return 'red'
      } else if (num === 0) {
        return ''
      } else {
        return 'green'
      }
    },

    // 获取烟厂
    async getFactory() {
      const { data } = await this.$api.offer.getFactory()
      // console.log(data);
      this.factoryList = data
    },

    // 文章
    async getArticleList(query) {
      const { data } = await this.$api.index.getArticleList(query)
      // console.log('发布文章', data);
      this.releaseList = data.list.slice(0, 10)
    },

    setChangeList(array) {
      // console.log(array)
      let arr = []
      let dataArr = []
      array.forEach((item) => {
        // console.log(item)
        if (arr.indexOf(item.Factory) < 0) {
          arr.push(item.Factory)
          dataArr.push([item])
        } else {
          dataArr[arr.indexOf(item.Factory)].push(item)
        }
      })

      const dataList = dataArr.filter((item) => {
        return item.length > 2
      })

      return dataList.slice(0, 5)
    },
  },

  // beforeRouteLeave(to, from, next) {
  //   console.log(to, from)
  //   if (this.$store.state.tokens) {
  //     if (this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1) {
  //       this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
  //         confirmButtonText: '续费',
  //         cancelButtonText: '取消',
  //         type: 'warning',
  //       })
  //         .then(() => {
  //           this.topUpFlag = !this.topUpFlag
  //         })
  //         .catch(() => {
  //           // next()
  //           console.log('取消')
  //         })
  //     } else {
  //       next()
  //     }
  //   } else {
  //     next()
  //   }
  // },

  activated() {
    // console.log(this.myChartList.list)
    if (this.myChartList.flag) {
      this.getUpdateList()
    }

    console.log('SET_SCROLL', this.$store.state.homeScroll)
    if (this.$store.state.homeScroll) {
      this.$store.commit('SET_SCROLL', 0)
      this.$nextTick(() => {
        document.documentElement.scrollTo({
          top: 1274,
          behavior: 'smooth',
        })
      })
    }
  },

  deactivated() {
    // console.log(this.myChartList.list)
    if (this.myChartList.list.length < 5) {
      this.myChartList.flag = true
    } else {
      this.myChartList.flag = false
    }
  },
}
</script>

<style scoped lang="scss">
.home {
  width: 100%;

  .home_center {
    width: 100%;
    position: relative;

    .indexBg {
      width: 100%;
      height: 292px;
      background: url('../../assets/img/indexBg.png') no-repeat;
      background-size: 1920px 292px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .content {
      width: 1090px;
      margin: 0 auto;
      padding-top: 22px;

      .chart {
        width: 1070px;
        height: 268px;
        background: rgba(0, 0, 0, 1);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.46);
        border-radius: 10px;
        padding: 20px 10px;
        display: flex;

        .chart_item {
          width: calc(20% - 20px);
          height: 268px;
          line-height: 22px;
          padding: 0 10px;
          text-align: left;
          border-right: 2px solid #313131;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #ffffff;

          .margin13 {
            margin-top: 13px;
          }

          .name_price {
            font-size: 17px;
            line-height: 23px;
            display: flex;
            justify-content: space-between;

            .name {
              display: inline-block;
              width: 50%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }

            .price {
              display: inline-block;
              flex: 1;
              text-align: right;
              font-size: 16px;
            }

            .red {
              color: #d83e26;
            }

            .green {
              color: #359f1f;
            }
          }

          .myChart {
            width: 190px;
            height: 86px;
          }
        }

        .chart_item:last-child {
          border: 0;
        }
      }
    }
  }
}
</style>
